import React from "react"
import { Row, Container, Col, Breadcrumb } from "react-bootstrap"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import IndexCard from "../components/indexCard"

const uri = "/cookbooks/"

const CookbooksPage = ( { location, data : { images } } ) => {
  const searchQuery = location.state?.searchQuery

  var imagearr = []
  for (var img of images.edges) {
    imagearr[img.node.name] = img.node.childImageSharp.gatsbyImageData
  }

  return (
  <Layout pageInfo={{ pageName: "cookbooks", searchQuery: searchQuery }} >
    <Seo title="Cookbooks" keywords={[`recipes`, `browse`]} description="Online cookbooks" url={uri} />

      <Container fluid className="text-center">
      <Row className="justify-content-left my-3">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Cookbooks</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
      <Col className="pb-4">
        <h1>Cookbooks</h1>
      </Col>
      </Row>
      <Row>
      <div className="d-flex justify-content-center flex-wrap">
      <IndexCard title="Camping Cookbook" 
        text = "Located on our sister site www.IloveItCamping.com, our online camping cookbook is a great source of recipes for your next outing."
        image={imagearr["campfire-896196_1280"]}
        imageAlt="Cookbook" 
        linkTo="https://www.iloveitcamping.com/cookbook"
      />

      <IndexCard title="Family Cookbook" 
        text = "Our online family cookbook has lots of great favorite recipes. These were collected from our family over the years. We hope you enjoy!"
        image={imagearr["cookbook2"]}
        imageAlt="Cookbook" 
        linkTo="/menke-family-cookbook/"
      />
      </div>
      </Row>

      
    </Container>
  </Layout>
)
}


export default CookbooksPage

export const cookbookPageQuery = graphql`
  query cookbookPageImages {
    images: allFile (filter: { sourceInstanceName: { eq: "index-page-images" }}) {
      edges {
        node {
          dir
          ext
          sourceInstanceName
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
